export function getAllUnitsForDocuments(defaultUnits: { [shortName: string]: string }) {
  const pdeUnit = defaultUnits[unitsShortName.pde] || "mg";
  const lddUnit = defaultUnits[unitsShortName.ldd];
  const dosageUnit = defaultUnits[unitsShortName.td] || "mg";
  const l3Unit = defaultUnits.L3 || DEFAULT_UNITS.L3;
  const l5Unit = defaultUnits.L5 || DEFAULT_UNITS.L5;
  const minBsUnit = defaultUnits[unitsShortName.min_bs];
  const surfaceAreaUnit = defaultUnits[unitsShortName.surface_area] || "sqcm";
  const ld50Unit = defaultUnits[unitsShortName.ld50] || "mg/kg";
  const strengthUnit = defaultUnits[unitsShortName.strength] || "mg";
  const volumeUnit = defaultUnits[unitsShortName.volume] || "ml";

  return {
    minBsUnit,
    surfaceAreaUnit,
    l3Unit,
    l5Unit,
    lddUnit,
    pdeUnit,
    dosageUnit,
    ld50Unit,
    strengthUnit,
    volumeUnit
  };
}

export enum unitsShortName {
  pde = "pde",
  td = "td",
  ldd = "ldd",
  min_bs = "min_bs",
  surface_area = "surface_area",
  ld50 = "ld50",
  strength = "strength",
  volume = "volume",
  tablet_weight = "tablet_weight"
}

export enum validDurationUnits {
  day = "day",
  days = "days",
  week = "week",
  weeks = "weeks",
  month = "month",
  months = "months",
  year = "year",
  years = "years"
}

export const DEFAULT_UNITS = {
  SURFACE_AREA: "sqcm",
  MIN_BS: "kg",
  L5: "ppm",
  L3: "mg/sqcm",
  L4: "mg",
  PDE: "mg",
  TD: "mg",
  STRENGTH: "mg",
  LD50: "mg/kg",
  VOLUME: "ml",
  LDD: "mg",
  TABLET_WEIGHT: "mg"
};
