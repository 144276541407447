import axios, { AxiosRequestConfig } from "axios";

export class HttpClient {
  static requestConfig(optionsIn?: { [index: string]: any }) {
    const options = optionsIn || {};
    const doNotCache = options.doNotCache;

    type headerType = {};

    const headers: headerType = {};

    if (doNotCache) {
      headers["Cache-Control"] = "no-cache, no-store, must-revalidate";
      headers["Pragma"] = "no-cache";
      headers["Expires"] = 0;
    }

    // headers["Cookie"] = `${COOKIE_NAME}=${sessionStorage.getItem(COOKIE_NAME)}`;

    return {
      headers
    };
  }

  static async post(url: string, data?: any, config?: AxiosRequestConfig) {
    const response = await axios.post(url, data, config);
    return response;
  }

  static async put(url: string, data?: any, config?: AxiosRequestConfig) {
    const response = await axios.put(url, data, config);
    return response;
  }

  static async get(url: string, config?: AxiosRequestConfig) {
    const response = await axios.get(url, config);
    return response;
  }
  static async delete(url: string, config?: AxiosRequestConfig) {
    const response = await axios.request({ ...config, method: "delete", url });
    return response;
  }
}
