import { StatusCodes } from "http-status-codes";
import { MicrobialResiduesType } from "./types/microbial";

export const ApplicableOptions = {
  validation: "Validation Only",
  verification: "Periodic Verification only",
  both: "Both"
};

export enum CleaningStatusTypes {
  PeriodicVerificationRequired = "PeriodicVerificationRequired",
  ValidationRequired = "ValidationRequired",
  Verified = "Verified",
  NotUsed = "NotUsed",
  EvaluationRequired = "EvaluationRequired",
  DedicatedEquipment = "DedicatedEquipment",
  DedicatedProduction = "DedicatedProduction",
  NotSetup = "NotSetup",
  SamplingMethodNotSetup = "SamplingMethodNotSetup",
  RevalidationRequired = "RevalidationRequired",
  VerificationRequiredForNewProduct = "VerificationRequiredForNewProduct",
  ValidationInProgress = "ValidationInProgress",
  PeriodicValidationRequired = "PeriodicValidationRequired",
  CleaningValidationSOPNotSetup = "CleaningValidationSOPNotSetup",
  VerificationRequired = "VerificationRequired",
  VerificationInProgress = "VerificationInProgress"
}

export enum MimeExtensionMap {
  "text/plain" = "txt",
  "application/msword" = "doc",
  "application/pdf" = "pdf",
  "application/vnd.ms-excel" = "xls",
  "application/vnd.ms-powerpoint" = "ppt",
  "application/x-7z-compressed" = "7z",
  "application/x-rar-compressed" = "rar",
  "application/zip" = "zip",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" = "docx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" = "xlsx"
}

export enum FixRunErrorType {
  Command = "Command",
  Batch = "Batch"
}

export enum MeaningOfSignature {
  Submit = "Submit",
  Approve = "Approve",
  Review = "Review",
  Reject = "Reject"
}

export enum AnalyticalMethodType {
  Product = "product",
  CleaningAgent = "cleaningAgent",
  BioBurden = "BioBurden",
  Endotoxin = "Endotoxin"
}

export enum AnalyticalMethodTypeVisual {
  product = "API",
  cleaningAgent = "Cleaning Agent",
  BioBurden = "BioBurden",
  Endotoxin = "Endotoxin"
}

export enum MeaningOfSignatureLabelType {
  Approve = "Approved",
  Review = "Reviewed",
  Submit = "Submitted",
  RejectOnSubmit = "Rejected",
  RejectOnReview = "Review Rejected",
  RejectOnApprove = "Approval Rejected"
}

export const MeaningOfSignaturesLabel = {
  [MeaningOfSignature.Approve]: "Approve",
  [MeaningOfSignature.Reject]: "Reject Assessment",
  [MeaningOfSignature.Review]: "Review",
  [MeaningOfSignature.Submit]: "Submit"
};

export const EmailMeaningOfSignatureLabel = {
  [MeaningOfSignature.Review]: "Review",
  [MeaningOfSignature.Approve]: "Approval"
};

export enum ResidueTypes {
  Product = "product",
  CleaningAgent = "cleaningAgent"
}

export type AllResidueTypes = ResidueTypes | MicrobialResiduesType | AdditionalTests;

export enum UserPlusOperatorType {
  User = "user",
  Operator = "operator"
}

// export enum FacilityCleaningEvaluationStatusType {
//   InProgress = "InProgress",
//   Stale = "Stale",
//   Done = "Done"
// }

export enum ValidationResidueCheck {
  Worst = "worst",
  Recent = "recent"
}

export enum ReportDocumentType {
  MACProtocol = "MACProtocol",
  CVProtocol = "CVProtocol",
  CVReport = "CVReport",
  CleaningValidationSOP = "CleaningValidationSOP",
  CleaningProcedure = "CleaningProcedure",
  AnalyticalMethod = "AnalyticalMethods",
  FailureHandlingSOP = "FailureHandlingSOP",
  CEReport = "CEReport",
  MACCalculationMatrix = "MACCalculationMatrix",
  ProductResidueReport = "ProductResidueReport",
  SamplingSheet = "SamplingSheet",
  AnnualSummaryReport = "AnnualSummaryReport"
}

export enum DocumentName {
  MACProtocol = "Limit and Worst Case Report",
  CVProtocol = "Validation Protocol",
  CVReport = "Validation Summary Report",
  CEReport = "Change Assessment Report",
  MACCalculationMatrix = "MAC Matrix",
  AnnualSummaryReport = "Annual Summary Report"
}

export enum LegacyDocumentType {
  LWC = "LWC",
  Assessments = "Assessments",
  Protocols = "Protocols",
  SummaryReports = "Summary Reports",
  AuditLogs = "Audit Logs",
  ReviewReports = "Review Reports"
}

export enum AssessmentState {
  NotEvaluated = "Not Evaluated",
  PendingApproval = "Pending Approval",
  Approved = "Approved",
  Cancelled = "Cancelled"
}

export enum SamplingMethodType {
  Swab = "swab",
  Rinse = "rinse"
}

export enum SamplingLocationsContactType {
  Direct = "Direct",
  Indirect = "Indirect"
}

export enum Operator {
  And = "and",
  Or = "or"
}

export enum ProductTier {
  Full = "full",
  Lite = "lite"
}

export enum HttpStatus {
  // [rfc7231] The 400 (Bad Request) status code indicates that the server cannot or
  // will not process the request due to something that is perceived to be
  // a client error (e.g., malformed request syntax, invalid request
  // message framing, or deceptive request routing).
  BadRequest = StatusCodes.BAD_REQUEST, // malformed request syntax, size too large, invalid request message framing, or deceptive request routing
  Unauthenticated = StatusCodes.UNAUTHORIZED, // authentication is required and has failed or has not yet been provided
  Forbidden = StatusCodes.FORBIDDEN, // Authenticated but not allowed
  // [rfc7231] server did not find a current representation for the target resource or is not willing to disclose that one exists.
  NotFound = StatusCodes.NOT_FOUND,

  // The request could not be completed due to a conflict with the current state of the resource.
  // This code is only allowed in situations where it is expected that the user might be able to resolve the conflict and resubmit the request.
  // The response body SHOULD include enough information for the user to recognize the source of the conflict.
  Conflict = StatusCodes.CONFLICT,

  // NOTE: 422 has been removed as of rfc7231
  // The 422 (Unprocessable Entity) status code means the server
  // understands the content type of the request entity
  // (hence a 415(Unsupported Media Type) status code is inappropriate),
  // and the syntax of the request entity is correct (thus a 400 (Bad Request) status code is inappropriate)
  // but was unable to process the contained instructions.
  UnprocessableEntity = StatusCodes.UNPROCESSABLE_ENTITY,
  InternalServerError = StatusCodes.INTERNAL_SERVER_ERROR,
  OK = StatusCodes.OK,
  PreconditionFailed = StatusCodes.PRECONDITION_FAILED,
  SeeOther = StatusCodes.SEE_OTHER
}

export enum ValidationStates {
  Validated = "Validated",
  OnGoing = "On Going",
  Failed = "Failed"
}

export enum RoomGradeNames {
  A = "Grade A",
  B = "Grade B",
  C = "Grade C",
  D = "Grade D"
}

export enum ClientErrors {
  TransactionLock = "TransactionLocked",
  NotAuthorized = "Not allowed"
}

export enum PasswordPolicyRules {
  minNumber = "One number",
  minCapital = "One uppercase character",
  specialCharacters = "One special character"
}

export enum PasswordPolicyConfiguration {
  MIN_NUMBER = "minNumber",
  MIN_CAPITAL = "minCapital",
  SPECIAL_CHAR = "specialCharacters",
  MIN_PASSWORD_LENGTH = "minPasswordLength",
  PASSWORD_MATCH = "passwordsMatch"
}

export enum CriteriaActiveLimit {
  DOSAGE = "Acceptable Residue Level (ARL) of the Target Residue (API) is set as one-one thousandth of its Minimum Daily Dose (MDD) in the Largest Daily Dose (LDD) of the next Drug Product",
  TOXICITY = "Acceptable Residue Level (ARL) of the Target Residue (API) is set as its ADE/PDE in the Largest Daily Dose (LDD) of the next Drug Product"
}

export enum AdditionalTests {
  TOC = "TOC",
  pH = "pH",
  Conductivity = "Conductivity",
  AA = "AA",
  Odour = "Odour",
  RC = "RC"
}

export enum FileType {
  XLSX = "xlsx",
  PDF = "pdf"
}

export enum UserProvider {
  LOCAL = "local",
  ACTIVEDIRECTORY = "ad",
  SSO = "sso"
}

export enum Comparator {
  LessThanEqualTo = "LessThanEqualTo",
  LessThan = "LessThan",
  GreaterThanEqualTo = "GreaterThanEqualTo",
  GreaterThan = "GreaterThan",
  EqualTo = "EqualTo"
}

export enum TargetType {
  Equipment = "Equipment",
  EquipmentGroup = "Equipment Group"
}

export enum SSOVerificationHandler {
  ChangeAssessment = "changeAssessment",
  ValidationStudies = "validationStudies",
  RunApproval = "runApproval"
}

export enum SessionStorageKeys {
  CURRENT_FACILITY = "current_facility",
  PRODUCT_TYPE = "product_type",
  FACILITY_TIER = "tier",
  FEATURE_FLAGS = "featureFlags",
  EMAIL = "email",
  USER_ID = "userId",
  ADDITIONAL_API_PROPERTIES = "apiAdditionalFields",
  ADDITIONAL_PRODUCT_PROPERTIES = "productAdditionalFields",
  ADDITIONAL_PRODUCTION_PROPERTIES = "productionAdditionalFields",
  NON_GENUINE_LICENSE_INFORMATION = "licenseInformation"
}

export enum CleaningLimitPolicyResidueType {
  Chemical = "Chemical",
  Detergent = "Detergent"
}

export enum MassUnits {
  mg = "mg",
  ug = "ug"
}

export enum ConcentrationLimitUnits {
  mgPerMl = "mg/ml",
  ppm = "ppm",
  ugPerMl = "ug/ml"
}

export enum LicenseState {
  INTIMATE = "intimate",
  GRACE = "grace",
  GRACE_EXCEEDED = "grace-exceeded",
  GENUINE = "genuine"
}
