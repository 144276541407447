import { getCleaningLimitPolicies } from "client/services/api";
import { defaultCleaningPolicy } from "common/constants";
import { CleaningLimitPolicyResidueType } from "common/enums";
import { PolicyConfigUI } from "common/types";
import { action, observable } from "mobx";

class CleaningLimitPolicies {
  @observable
  cleaningLimitPolicies: { [index in CleaningLimitPolicyResidueType]: PolicyConfigUI } = {
    [CleaningLimitPolicyResidueType.Chemical]: { ...defaultCleaningPolicy },
    [CleaningLimitPolicyResidueType.Detergent]: {
      ...defaultCleaningPolicy,
      residueType: CleaningLimitPolicyResidueType.Detergent
    }
  };

  @observable
  safetyFactor: { [index in CleaningLimitPolicyResidueType]?: number } = {};

  @action
  fetchCleaningLimitPolicies = () => {
    Object.values(CleaningLimitPolicyResidueType).forEach(async residueType => {
      try {
        const { policy, safetyFactor } = await getCleaningLimitPolicies(residueType);
        this.cleaningLimitPolicies[residueType] = policy;
        this.safetyFactor[residueType] = safetyFactor;
      } catch (e) {
        console.error(e);
      }
    });
  };

  @action
  fetchCleaningLimitPolicy = async (residueType: CleaningLimitPolicyResidueType) => {
    try {
      const { policy, safetyFactor } = await getCleaningLimitPolicies(residueType);
      this.cleaningLimitPolicies[residueType] = policy;
      this.safetyFactor[residueType] = safetyFactor;
    } catch (e) {
      console.error(e);
    }
  };
}

export const CleaningLimitPoliciesState = new CleaningLimitPolicies();
